<template>
    <SmartForm
        base_url="/sportingclub"
        base_path="/sportingclub"
        form_type="view"
    ></SmartForm>
</template>

<script>
 import SmartForm from "@/view/components/SmartForm.vue";
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     components: {
         SmartForm,
     },
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Affiliazioni", route: "/sportingclub/index" },
             { title: "Dettagli Sodalizio" }
         ]);
     }
 };
</script>
